import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-async'

import './Config.css'

const getConfig = async ({ param }, { signal }) => {
  return window.threeCommasManagerClient.getConfig()
}

const ConfigDashboard = () => {
  const [state, setState] = useState({ })
  const { value, error, isPending } = useAsync({ promiseFn: getConfig, param: 1 })

  useEffect(() => {
    if (value) setState(oldState => ({ ...oldState, lunarCrush: 'N/A' }))
  }, [value])

  async function updateConfig () {
    const newConfig = {
      lunarCrush: {
        key: state.LunarCrushAPIKey
      }
    }
    const res = await window.threeCommasManagerClient.updateConfig(/* state.config */ newConfig)
    setState(oldState => ({ ...oldState, config: res }))
  }

  function handleUpdateBtn () {
    updateConfig()
  }

  async function checkLunarCrush () {
    const res = await window.threeCommasManagerClient.testLunarCrush()
    setState(oldState => ({ ...oldState, lunarCrush: res }))
  }

  function handleCheckLunarCrushBtn () {
    checkLunarCrush()
  }

  function handleLunarCrushAPIKeyChange (e) {
    setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  if (isPending) return 'Loading...'
  if (error) return `Something went wrong: ${error.message}`

  return (
    <>
      <input className="textInput textInput_md" type="text" name="LunarCrushAPIKey" defaultValue={state.LunarCrushAPIKey} onChange={handleLunarCrushAPIKeyChange}></input>
      <button className={'button button_primary button_md'} onClick={handleUpdateBtn}>Update</button>
      <button className={'button button_primary button_md'} onClick={handleCheckLunarCrushBtn}>Check LunarCrush</button>
      <pre>{ JSON.stringify(state.lunarCrush, false, 2) }</pre>
    </>
  )
}

class Config extends React.Component {
  render () {
    return (
        <div id='content' className='config'>
          <ConfigDashboard />
        </div>
    )
  }
}

export default Config
