import React from 'react'

import './Signals.css'

class Signals extends React.Component {
  render () {
    return (
        <div id='content' className='signals'>
          Signals
        </div>
    )
  }
}

export default Signals
