import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './ProfilePanel.css'

const ProfilePanel = ({ history }) => {
  const [bShowProfilePanel, setbShowProfilePanel] = useState(false)

  const profilePanelContainerBlur = (e) => {
    if (!e.relatedTarget) {
      hideProfilePanel()
    }
  }

  useEffect(() => {

  })

  function showProfilePanel (event) {
    setbShowProfilePanel(true)
  }

  function hideProfilePanel (event) {
    setbShowProfilePanel(false)
  }

  return (
    <div
      id="profilePanelContainer" onBlur={profilePanelContainerBlur}
    >
      <div id="profilePanelTogglerContainer">
        <button id="profilePanelToggler"
          onClick={() =>
            bShowProfilePanel
              ? hideProfilePanel()
              : showProfilePanel()}
        >
          <i className="fas fa-user"></i>
          <span className="username">{window.threeCommasManagerClient.token.sub}</span>
          <i className="fas fa-caret-down"></i>
        </button>
      </div>
      <div id="profilePanel" className={bShowProfilePanel ? 'show' : ''}>
        <div className="btnBlock">
          <button className="button button_primary button_sm button_block" onClick={(e) => { window.threeCommasManagerClient.destroySession(); history.push('/login'); hideProfilePanel() }}>
            <span>Log out</span>
          </button>
        </div>
      </div>
    </div>
  )
}

ProfilePanel.propTypes = {
  history: PropTypes.any
}

export default ProfilePanel
