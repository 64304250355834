import React from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Navigator } from '../Navigator'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const history = useHistory()
  window.pushHistory = (...args) => history.push.apply(this, args)

  return (
    <Route
    {...rest}
    render={props =>
      window.threeCommasManagerClient.hasValidSession()
        ? (
            <>
              <Navigator history={props.history} />
              <Component {...props} />
            </>
          )
        : (
            <Redirect
              to={{
                pathname: '/login'
              }}
            />
          )
    }
    />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.any,
  history: PropTypes.any
}

export default ProtectedRoute
