import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-async'
import PropTypes from 'prop-types'

import './Pairs.css'

const SuggestedPairGroupData = ({ groupId, data, context }) => {
  const { toAdd, toRemove } = data
  return (
    <div>
      <div>{groupId}</div>
      <div>To add: {toAdd.map(currency => currency.symbol).join(', ')}</div>
      <div>To remove: {toRemove.map(currency => currency.symbol).join(', ')}</div>
    </div>
  )
}

SuggestedPairGroupData.propTypes = {
  groupId: PropTypes.any,
  data: PropTypes.any,
  context: PropTypes.any
}

const loadSuggestedPairs = async ({ param }, { signal }) => {
  return window.threeCommasManagerClient.getSuggestedPairs()
  /* return new Promise((resolve) => {
    setTimeout(async () => {
      const res = window.threeCommasManagerClient.getSuggestedPairs()
      resolve(res)
    }, 1000)
  }) */
}

const PairsDashboard = () => {
  const [state, setState] = useState({ pairs: [] })
  const { value, error, isPending } = useAsync({ promiseFn: loadSuggestedPairs, param: 1 })

  useEffect(() => {
    if (value) setState(oldState => ({ pairs: value.byMarketCap }))
  }, [value])

  async function refreshPairs () {
    const res = await window.threeCommasManagerClient.updateSuggestedPairs()
    setState(oldState => ({ pairs: res.byMarketCap }))
  }

  function handleRefreshBtn () {
    refreshPairs()
  }

  if (isPending) return 'Loading...'
  if (error) return `Something went wrong: ${error.message}`

  return (
    <>
      <button className={'button button_primary button_md'} onClick={handleRefreshBtn}>Refresh</button>
      <div className="SuggestedPairContainerList">
        {
          Object.keys(state.pairs).map((groupId) => {
            const context = {
              groupId,
              groupData: state.pairs[groupId]
            }
            return (
                <div className="SuggestedPairRowContainer" key={`${groupId}:suggestedPair`}>
                  <SuggestedPairGroupData groupId={groupId} data={state.pairs[groupId]} context={context} />
                </div>
            )
          })
        }
      </div>
    </>
  )
}

const SuggestedLeveragedPairGroupData = ({ groupId, data, context }) => {
  return (
    <div>
      <div>{groupId}</div>
      {<div>Available: {data.map(currency => currency.pair).join(', ')}</div>}
      {/* <pre>Available: {JSON.stringify(data, false, 2)}</pre> */}
    </div>
  )
}

SuggestedLeveragedPairGroupData.propTypes = {
  groupId: PropTypes.any,
  data: PropTypes.any,
  context: PropTypes.any
}

const loadSuggestedLeveragedPairs = async ({ param }, { signal }) => {
  return window.threeCommasManagerClient.getSuggestedLeveragedPairs()
}

const PairsLeveragedDashboard = () => {
  const [state, setState] = useState({ pairs: [] })
  const { value, error, isPending } = useAsync({ promiseFn: loadSuggestedLeveragedPairs, param: 1 })

  useEffect(() => {
    if (value) setState(oldState => ({ pairs: value }))
  }, [value])

  async function refreshPairs () {
    const accountId = 31547490
    const res = await window.threeCommasManagerClient.updateSuggestedLeveragedPairs({ accountId, minLeverage: 20.0, minLotSize: 5 })
    setState(oldState => ({ pairs: res }))
  }

  function handleRefreshBtn () {
    refreshPairs()
  }

  if (isPending) return 'Loading...'
  if (error) return `Something went wrong: ${error.message}`

  return (
    <>
      <button className={'button button_primary button_md'} onClick={handleRefreshBtn}>Refresh</button>
      <div className="SuggestedPairContainerList">
        {
          Object.keys(state.pairs).map((groupId) => {
            if (!state.pairs[groupId].length) return undefined
            const context = {
              groupId,
              groupData: state.pairs[groupId]
            }
            return (
                <div className="SuggestedPairRowContainer" key={`${groupId}:suggestedPair`}>
                  <SuggestedLeveragedPairGroupData groupId={groupId} data={state.pairs[groupId]} context={context} />
                </div>
            )
          })
        }
      </div>
    </>
  )
}

class Pairs extends React.Component {
  render () {
    return (
        <div id='content' className='pairs'>
          <PairsDashboard />
          <PairsLeveragedDashboard />
        </div>
    )
  }
}

export default Pairs
