import React from 'react'
import {
  Link
} from 'react-router-dom'
import PropTypes from 'prop-types'

import { ProfilePanel } from '../ProfilePanel'

import './Navigator.css'

const Navigator = ({ history }) => {
  const menuData = [
    ['/', 'Home'],
    ['/risk', 'Risk'],
    ['/batchEditor', 'Batch Editor'],
    ['/pairs', 'Pairs'],
    ['/recoveryGrid', 'Recovery Grid'],
    ['/signals', 'Signals'],
    ['/config', 'Config']
  ]
  return (
    <nav id='navigator'>
      <ProfilePanel history={history} />
      <input id="mobileTriggerMenuToggler" type="checkbox" />
      <div id="mobileBurgerMenuToggler">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul>
        {
          menuData.map(([link, label]) =>
            (
              <li key={link}>
                <Link to={link}>{label}</Link>
              </li>
            )
          )
        }
      </ul>
    </nav>
  )
}

Navigator.propTypes = {
  history: PropTypes.any
}

export default Navigator
