import React, { useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import PropTypes from 'prop-types'
import { useAsync } from 'react-async'

import './Risk.css'

const { RiskCalc } = require('./riskCalc')

const riskCalc = new RiskCalc({ logger: window.logger })

function createRiskGauge (canvas, risk, activeDealsRisk, configRisk) {
  if (!canvas) return

  const opts = {
    angle: -0.2, // The span of the gauge arc
    lineWidth: 0.1, // The line thickness
    radiusScale: 1, // Relative radius
    pointer: {
      length: 0.55, // // Relative to gauge radius
      strokeWidth: 0.035, // The thickness
      color: '#b4c2ce' // Fill color
    },
    limitMax: true, // If false, max value increases automatically if value > maxValue
    limitMin: true, // If true, the min value of the gauge will be fixed
    colorStart: '#6FADCF', // Colors
    colorStop: '#8FC0DA', // just experiment with them
    strokeColor: '#E0E0E0', // to see which ones work best for you
    generateGradient: true,
    highDpiSupport: true, // High resolution support,
    // renderTicks is Optional
    renderTicks: {
      divisions: 4,
      divWidth: 1.1,
      divLength: 0.7,
      divColor: '#333333',
      subDivisions: 0,
      subLength: 0.5,
      subWidth: 0.6,
      subColor: '#666666'
    },
    staticZones: [
      { strokeStyle: '#30B32D', min: 0, max: 100 }, // Green
      { strokeStyle: '#FFDD00', min: 100, max: 300 }, // Yellow
      { strokeStyle: '#F03E3E', min: 300, max: 500 } // Red
    ]
  }
  const gauge = new window.Gauge(canvas).setOptions(opts) // create sexy gauge!
  gauge.maxValue = 500 // set max gauge value
  gauge.setMinValue(0) // Prefer setter over gauge.minValue = 0
  gauge.animationSpeed = 32 // set animation speed (32 is default value)
  gauge.set([configRisk, risk, activeDealsRisk]) // set actual value

  const activeDealsRiskPointerOptions = {
    length: 0.55, // // Relative to gauge radius
    strokeWidth: 0.035, // The thickness
    color: '#3f97e9' // Fill color
  }
  gauge.gp[2].setOptions(activeDealsRiskPointerOptions)

  const riskPointerOptions = {
    length: 0.55, // // Relative to gauge radius
    strokeWidth: 0.045, // The thickness
    color: '#00a59a' // Fill color
  }
  gauge.gp[1].setOptions(riskPointerOptions)

  const configRiskPointerOptions = {
    length: 0.55, // // Relative to gauge radius
    strokeWidth: 0.055, // The thickness
    color: '#ee7d8b' // Fill color
  }
  gauge.gp[0].setOptions(configRiskPointerOptions)
  return gauge
}

function updateRiskGauge (gauge, risk, activeDealsRisk, configRisk) {
  if (!gauge) return

  // mechanical sympathy hack to trigger update of non-main pointers
  gauge.displayedValue = 0
  gauge.set([configRisk, risk, activeDealsRisk])

  return gauge
}

const RiskGauge = ({ risk, activeDealsRisk, configRisk, context }) => {
  const refCanvas = useRef()
  let riskGauge = {}
  context.getRiskGauge = () => riskGauge

  useEffect(() => {
    riskGauge = createRiskGauge(refCanvas.current, risk, activeDealsRisk, configRisk)
  })

  return (
    <canvas ref={refCanvas} className="gauge" width="170" height="150"></canvas>
  )
}

RiskGauge.propTypes = {
  risk: PropTypes.number,
  activeDealsRisk: PropTypes.number,
  configRisk: PropTypes.number,
  context: PropTypes.any
}

const RiskLabel = ({ risk, activeDealsRisk, configRisk, context }) => {
  // eslint-disable-next-line no-unused-vars
  const [configRiskVal, setConfigRiskVal] = useState(`${configRisk.toFixed(2)}`)
  context.getConfigRiskVal = () => configRiskVal
  const [riskVal, setRiskVal] = useState(`${risk.toFixed(2)}`)

  function handleRiskChange (event) {
    if (context.setApplyButtonVisible) { context.setApplyButtonVisible(true) }
    const riskPercent = event.target.value
    if (riskPercent === '') return
    setConfigRiskVal(riskPercent)
    const botsData = [...context.getBotsData()]
    const bots = botsData.map(botData => botData.bot)
    const botsToUpdate = riskCalc.getFixedPercentNewBotsConfig(context.account, context.currencyData, riskPercent)
    botsData.bots = botsToUpdate
    context.setBotsDataPreview(botsData)
    const maxUsageAccountCurrencyFromConfig = riskCalc.getMaxUsageAccountCurrencyFromConfig(context.account, bots)
    const bankrollTotal = context.currencyData.position + context.currencyData.activeDealsReserved
    const maxUsageAccountCurrencyFromActiveDeals = activeDealsRisk * bankrollTotal / 100
    const activeDealsCount = context.currencyData.botsData[0].bot.active_deals_count
    const maxActiveDeals = context.currencyData.botsData[0].bot.max_active_deals
    const maxUsageAccountCurrencyFromActiveAndMaxActiveDeals = maxUsageAccountCurrencyFromConfig / maxActiveDeals * (maxActiveDeals - activeDealsCount) + maxUsageAccountCurrencyFromActiveDeals
    const accountCurrencyConfigRisk = maxUsageAccountCurrencyFromConfig * 100 / bankrollTotal
    const accountCurrencyRisk = maxUsageAccountCurrencyFromActiveAndMaxActiveDeals * 100 / bankrollTotal
    // console.log(accountCurrencyConfigRisk)
    // const newVal = parseFloat(event.target.value)
    // setConfigRiskVal(`${accountCurrencyConfigRisk.toFixed(2)}`)
    setRiskVal(`${accountCurrencyRisk.toFixed(2)}`)

    const riskGauge = context.getRiskGauge()
    updateRiskGauge(riskGauge, accountCurrencyRisk, activeDealsRisk, accountCurrencyConfigRisk)
  }

  function handleRiskKeyPress (event) {
    const character = String.fromCharCode(event.which)
    if (isNaN(character) && character !== '.') event.preventDefault()
  }

  return (
    <div className="gaugeValContainer">
      <div className="gaugeVal activeDealsRisk">{activeDealsRisk.toFixed(2)}%</div>
      <div className="gaugeVal risk">{riskVal}%</div>
      <div className="gaugeVal configRisk">
        <ContentEditable className="gaugeLabel" html={configRiskVal} onChange={handleRiskChange} onKeyPress={handleRiskKeyPress} />%
      </div>
    </div>
  )
}

RiskLabel.propTypes = {
  risk: PropTypes.number,
  activeDealsRisk: PropTypes.number,
  configRisk: PropTypes.number,
  context: PropTypes.any
}

const GaugeLabel = ({ val, context }) => {
  return (
    <div className="gaugeValContainer">
      {val}
    </div>
  )
}

GaugeLabel.propTypes = {
  val: PropTypes.string,
  context: PropTypes.any
}

function updateBankrollGauge (target, val) {
  if (!target) return

  const opts = {
    angle: -0.2, // The span of the gauge arc
    lineWidth: 0.1, // The line thickness
    radiusScale: 1, // Relative radius
    pointer: {
      length: 0.55, // // Relative to gauge radius
      strokeWidth: 0.035, // The thickness
      color: '#b4c2ce' // Fill color
    },
    limitMax: false, // If false, max value increases automatically if value > maxValue
    limitMin: false, // If true, the min value of the gauge will be fixed
    colorStart: '#6FADCF', // Colors
    colorStop: '#8FC0DA', // just experiment with them
    strokeColor: '#E0E0E0', // to see which ones work best for you
    generateGradient: true,
    highDpiSupport: true, // High resolution support,
    // renderTicks is Optional
    renderTicks: {
      divisions: 4,
      divWidth: 1.1,
      divLength: 0.7,
      divColor: '#333333',
      subDivisions: 0,
      subLength: 0.5,
      subWidth: 0.6,
      subColor: '#666666'
    },
    staticZones: [
      { strokeStyle: 'rgb(255, 71, 26)', min: 0, max: 20 },
      { strokeStyle: 'rgb(246, 150, 30)', min: 20, max: 40 },
      { strokeStyle: 'rgb(236, 219, 35)', min: 40, max: 60 },
      { strokeStyle: 'rgb(174, 226, 40)', min: 60, max: 80 },
      { strokeStyle: 'rgb(106, 215, 45)', min: 80, max: 100 }
    ]
  }
  const gauge = new window.Gauge(target).setOptions(opts) // create sexy gauge!
  gauge.maxValue = 100 // set max gauge value
  gauge.setMinValue(0) // Prefer setter over gauge.minValue = 0
  gauge.animationSpeed = 32 // set animation speed (32 is default value)
  gauge.set(val) // set actual value
}

const BankrollGauge = ({ val }) => {
  const refCanvas = useRef()

  useEffect(() => {
    updateBankrollGauge(refCanvas.current, val)
  })

  return (
    <canvas ref={refCanvas} className="gauge" width="170" height="150"></canvas>
  )
}

BankrollGauge.propTypes = {
  val: PropTypes.any
}

const RiskData = ({ data, context }) => {
  const [applyButtonVisible, setApplyButtonVisible] = useState(false)
  const [botsData, setBotsData] = useState(data.botsData)
  const [botsDataPreview, setBotsDataPreview] = useState(data.botsData)
  context.setApplyButtonVisible = setApplyButtonVisible
  context.setBotsData = setBotsData
  context.getBotsData = () => botsData
  context.setBotsDataPreview = setBotsDataPreview
  context.getBotsDataPreview = () => botsDataPreview
  const { account, currency } = context
  const bots = botsDataPreview.map(botData => botData.bot)

  async function handleApplyRisk () {
    const mode = context.getRiskMode()
    let config
    if (mode === 'simple') {
      const bots = botsDataPreview.map(botData => botData.bot)
      config = bots
    } else if (mode === 'fixedVolume' || mode === 'fixedPercent') {
      config = {
        val: context.getConfigRiskVal()
      }
    }
    const updateBotsData = {
      mode,
      account,
      currency,
      config
    }
    await window.threeCommasManagerClient.updateBots(updateBotsData)
    setApplyButtonVisible(false)
  }

  function recapStringFromBotData (bot) {
    const quoteCurrency = bot.pairs[0].split('_')[0]
    return `TP: ${bot.take_profit}, BO: ${bot.base_order_volume} ${quoteCurrency}, SO: ${bot.safety_order_volume} ${quoteCurrency}, SOS: ${bot.safety_order_step_percentage}%, OS: ${bot.martingale_volume_coefficient}, SS: ${bot.allowed_deals_on_same_pair}`
  }

  return (
    <div className="riskDataContainer">
      <div className="botDataRecapList">
        {
          bots.map((bot) => (
            <div className="botDataRecap" key={bot.id}>
              <div className="botDataRecapName">{bot.name}</div>
              <div className="botDataRecapParams">{recapStringFromBotData(bot)}</div>
            </div>
          ))
        }
      </div>
      <div className="bottomRow">
        <RiskMode data={data} context={context} />
        <button className={`button button_primary button_md applyRiskBtn ${applyButtonVisible ? '' : 'hidden'}`} onClick={handleApplyRisk}>Apply</button>
      </div>
    </div>
  )
}

RiskData.propTypes = {
  data: PropTypes.any,
  context: PropTypes.any
}

const RiskMode = ({ data, context }) => {
  const { config } = data
  let riskModeInitial = 'simple'
  if (config) {
    const { mode } = config
    riskModeInitial = mode
  }
  const [riskMode, setRiskMode] = useState(riskModeInitial)
  context.getRiskMode = () => riskMode
  const { account, currency } = context
  const id = `${account}:${currency}`

  function onChangeValue (event) {
    console.log(event.target.value)
    if (context.setApplyButtonVisible) { context.setApplyButtonVisible(true) }
    setRiskMode(event.target.value)
  }

  return (
    <div className="riskModeContainer" value={riskMode} onChange={onChangeValue} >
      <label htmlFor={`${id}:simple`}><input id={`${id}:simple`} type="radio" value="simple" name={`${id}:riskMode`} defaultChecked={riskMode === 'simple'} />Simple</label>
      <label htmlFor={`${id}:fixedVolume`}><input id={`${id}:fixedVolume`} type="radio" value="fixedVolume" name={`${id}:riskMode`} defaultChecked={riskMode === 'fixedVolume'} />Fixed Vol.</label>
      <label htmlFor={`${id}:fixedPercent`}><input id={`${id}:fixedPercent`} type="radio" value="fixedPercent" name={`${id}:riskMode`} defaultChecked={riskMode === 'fixedPercent'} />Fixed %</label>
    </div>
  )
}

RiskMode.propTypes = {
  data: PropTypes.any,
  context: PropTypes.any
}

// You can use async/await or any function that returns a Promise
const loadRisk = async ({ param }, { signal }) => {
  return window.threeCommasManagerClient.getRisk()
}

const RiskDashboard = () => {
  const { data, error, isPending } = useAsync({ promiseFn: loadRisk, param: 1 })
  if (isPending) return 'Loading...'
  if (error) return `Something went wrong: ${error.message}`
  if (data) {
    return (
      <>
        <div id="globalRisk">
          <div className="gaugeContainer">
            <BankrollGauge val={data.bankrollData.bankrollAvailablePercent} />
            <GaugeLabel val={`${data.bankrollData.bankrollAvailableUSD.toFixed(0)}/${data.bankrollData.bankrollTotalUSD.toFixed(0)} (${data.bankrollData.bankrollAvailablePercent.toFixed(0)}%)`} context={{}} />
          </div>
        </div>
        <div className="currencyRiskContainerList">
          {
            Object.keys(data.accounts).map((account) => {
              return Object.keys(data.accounts[account]).map((currency) => {
                const currencyData = data.accounts[account][currency]
                const context = {
                  account: parseInt(account),
                  currency,
                  currencyData
                }
                return (
                  <div className="currencyRiskRowContainer" key={`${account}:${currency}:risk`}>
                    <div className="currencyRiskContainer">
                      <div className="gaugeContainer">
                        <BankrollGauge val={currencyData.bankrollAvailablePercent} />
                        <GaugeLabel val={`${currencyData.bankrollAvailableUSD.toFixed(0)}/${currencyData.bankrollTotalUSD.toFixed(0)} (${currencyData.bankrollAvailablePercent.toFixed(0)}%)`} context={context} />
                      </div>
                      <div className="gaugeContainer">
                        <RiskGauge risk={currencyData.risk} activeDealsRisk={currencyData.activeDealsRisk} configRisk={currencyData.configRisk} context={context} />
                        <RiskLabel risk={currencyData.risk} activeDealsRisk={currencyData.activeDealsRisk} configRisk={currencyData.configRisk} context={context} />
                      </div>
                      <RiskData data={currencyData} context={context} />
                    </div>
                  </div>
                )
              })
            })
          }
        </div>
      </>
    )
  }
  return null
}

class Risk extends React.Component {
  render () {
    return (
        <div id='content' className='risk'>
          <RiskDashboard />
        </div>
    )
  }
}

export default Risk
