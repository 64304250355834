import React from 'react'

import './RecoveryGrid.css'

class RecoveryGrid extends React.Component {
  render () {
    return (
        <div id='content' className='recoveryGrid'>
          RecoveryGrid
        </div>
    )
  }
}

export default RecoveryGrid
