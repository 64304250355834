/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-async'
import { Line } from 'react-chartjs-2'
import Slider from '@mui/material/Slider'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import Typography from '@mui/material/Typography'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import * as Utils from 'Lib/ChartJSUtils'

import './Home.css'
import 'MUIOverrides.css'

const cache = createCache({
  key: 'css',
  prepend: true
})

/*
P is the principal amount.
R is the annual interest rate.
t is the time the money is invested or borrowed for.
n is the number of times that interest is compounded per unit t,
for example if interest is compounded monthly and t is in years then the value of n would be 12.
If interest is compounded quarterly and t is in years then the value of n would be 4.
*/
const calcCompoundInterest = ({ principal, time, rate, n }) => {
  const amount = principal * Math.pow(1 + (rate / 100), (n * time))
  const interest = amount - principal
  return interest
}

const calcCompoundInterestWithMonthlySalary = ({ principal, time, rate, n, advMonths, monthlySalaryUSD }) => {
  let amount = principal
  const advantage = advMonths * 30
  let advantageCnt = 0
  for (let j = 0; j < n; j++) {
    for (let i = 0; i < time; i++, advantageCnt++) {
      amount += amount * rate / 100
      if (!(i % 30) && advantageCnt >= advantage) {
        amount -= monthlySalaryUSD
      }
    }
  }
  const interest = amount - principal
  return interest
}

const calcNetForecast = ({ principal, time, rate, n, advMonths, monthlySalaryUSD, GBPUSDRate, income, preExistingGains }) => {
  let amount = principal
  const advantage = advMonths * 30
  let advantageCnt = 0
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  let interest = 0
  const netForecast = []
  for (let j = 0; j < n; j++) {
    for (let i = 0; i < time; i++, advantageCnt++) {
      date.setDate(date.getDate() + 1)
      const isTaxDay = date.getDate() === 5 && date.getMonth() === 3
      if (isTaxDay) {
        const taxTot = calcTaxes({ gain: interest / GBPUSDRate + preExistingGains, income })
        amount -= taxTot * GBPUSDRate
      }
      amount += amount * rate / 100
      if (!(i % 30) && advantageCnt >= advantage) {
        amount -= monthlySalaryUSD
      }
      interest = amount - principal
      netForecast.push({
        date: new Date(date),
        interest
      })
    }
  }
  return netForecast
}

// https://www.gov.uk/income-tax-rates
// https://www.gov.uk/government/publications/rates-and-allowances-income-tax/income-tax-rates-and-allowances-current-and-past
// https://taxscouts.com/calculator/capital-gains-tax/
const calcTaxes = ({ gain, income }) => {
  const tiers = [
    {
      taxable: 37700,
      rate: 10
    },
    {
      taxable: Infinity,
      rate: 20
    }
  ]
  const basicRate = 50270
  let taxTot = 0
  const freeAllowance = 12300
  let gainLeft = Math.max(gain - freeAllowance, 0)
  const usableStartingTier = Math.min(Math.max(basicRate - income, 0), tiers[0].taxable)
  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i]
    const usableTierLeft = (i === 0) ? usableStartingTier : tier.taxable
    const toTax = Math.min(usableTierLeft, gainLeft)
    const taxTier = toTax * tier.rate / 100
    taxTot += taxTier
    gainLeft -= toTax
    if (gainLeft === 0) {
      break
    }
  }

  return taxTot
}

const getStats = async ({ param }, { signal }) => {
  return window.threeCommasManagerClient.getStats()
}

const StatsDashboard = () => {
  const [state, setState] = useState({ })
  const { value, error, isPending } = useAsync({ promiseFn: getStats })

  useEffect(() => {
    if (value) {
      const { total, avgProfit, avgProfitPercent, tickers } = value
      // const avgProfitPercent = 0.12
      if (!total) return

      setState(oldState => ({ ...oldState, value }))
    }
  }, [value])

  if (isPending) return 'Loading...'
  if (error) return `Something went wrong: ${error.message}`

  return (
    <>
      <div className="stats">
        <StatsDashboardVisualizer value={state.value} />
        <div className="statsRawData">
          <pre>{ JSON.stringify(Object.keys(state).reduce((acc, key) => ['data', 'netForecast'].includes(key) ? acc : { ...acc, ...{ [key]: state[key] } }, {}), false, 2) }</pre>
        </div>
      </div>
    </>
  )
}

const StatsDashboardVisualizer = (props) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const defaultForecastDate = new Date(today)
  defaultForecastDate.setDate(defaultForecastDate.getDate() + 365)

  const [state, setState] = useState({ goalDate: defaultForecastDate, salary: 3000 })
  const [dataView, setDataView] = useState({ data: { labels: [], datasets: [] } })

  useEffect(() => {
    const { value } = props
    if (!value) return
    const { total, avgProfit, avgProfitPercent, tickers } = value
    // const avgProfitPercent = 0.12
    if (!total) return

    const GBPUSDRate = parseFloat(tickers.GBPBUSD)
    const additionalBase = 0 * GBPUSDRate

    const goalDays = Math.round((state.goalDate - today) / (1000 * 60 * 60 * 24))
    // const goalDays = 365 * 4 + 30 * 6
    const preExistingGains = 19000
    const income = 70000
    const monthlySalaryGBP = state.salary
    const monthlySalaryUSD = monthlySalaryGBP * GBPUSDRate
    const goalDate = new Date()
    goalDate.setDate(goalDate.getDate() + goalDays)
    const interestUSD = calcCompoundInterest({
      principal: total + additionalBase,
      time: goalDays,
      rate: avgProfitPercent,
      n: 1
    })
    const advMonths = 0
    const interestSalaryUSD = calcCompoundInterestWithMonthlySalary({
      principal: total + additionalBase,
      time: goalDays,
      rate: avgProfitPercent,
      n: 1,
      advMonths,
      monthlySalaryUSD
    })
    const netForecast = calcNetForecast({
      principal: total + additionalBase,
      time: goalDays,
      rate: avgProfitPercent,
      n: 1,
      advMonths,
      monthlySalaryUSD,
      GBPUSDRate,
      income,
      preExistingGains
    })
    const interests = Object.keys(tickers).reduce((acc, pair) => ({ ...acc, [pair.split('BUSD')[0]]: interestUSD / parseFloat(tickers[pair]) }), {})
    interests.USD = interestUSD
    const interestsSalary = Object.keys(tickers).reduce((acc, pair) => ({ ...acc, [pair.split('BUSD')[0]]: interestSalaryUSD / parseFloat(tickers[pair]) }), {})
    interestsSalary.USD = interestSalaryUSD
    const avgProfitReport = Object.keys(tickers).reduce((acc, pair) => ({ ...acc, [pair.split('BUSD')[0]]: avgProfit / parseFloat(tickers[pair]) }), {})
    avgProfitReport.USD = avgProfit
    const totalReport = Object.keys(tickers).reduce((acc, pair) => ({ ...acc, [pair.split('BUSD')[0]]: total / parseFloat(tickers[pair]) }), {})
    totalReport.USD = total
    // const testTaxes = calcTaxes({ gain: 70000, income: 20000 })
    const testTaxes = calcTaxes({ gain: 12600, income: 20000 })
    const taxes = calcTaxes({ gain: interests.GBP + preExistingGains, income })
    const netInterests = interests.GBP - taxes
    const grossSalaryGBP = ((12 - advMonths) * monthlySalaryGBP) + interestsSalary.GBP
    const taxesSalary = calcTaxes({ gain: grossSalaryGBP, income })
    const netSalary = grossSalaryGBP - taxes

    const data = {
      labels: netForecast.map(({ date }) => date.getDate() + '/' + (parseInt(date.getMonth()) + 1) + '/' + date.getFullYear()),
      datasets: [
        {
          label: 'Profits',
          backgroundColor: 'rgba(255, 99, 132, 0.1)',
          borderColor: Utils.CHART_COLORS.red,
          data: netForecast.map(val => val.interest / GBPUSDRate),
          fill: true,
          pointRadius: 0,
          borderWidth: 1
        }
      ]
    }

    setDataView(oldState => ({ ...oldState, data, netForecast, interests, interestsSalary, avgProfitReport, totalReport, taxes, netInterests, taxesSalary, netSalary, testTaxes, goalDate }))
  }, [state, props])

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Forecasts'
      }
    },
    interaction: {
      mode: 'index',
      intersect: false
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Month'
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Value'
        }
      }
    }
  }

  return (
    <>
      <CacheProvider value={cache}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Goal Date"
            value={dataView.goalDate}
            onChange={(goalDate) => {
              setState(oldState => ({ ...oldState, goalDate }))
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Typography gutterBottom>Salary</Typography>
        <Slider
          aria-label="Salary"
          value={state.salary}
          onChange={(event, salary) => {
            setState(oldState => ({ ...oldState, salary }))
          }}
          valueLabelDisplay="auto"
          step={500}
          marks
          min={0}
          max={5000}
        />
        <Line data={dataView.data} options={options} width={640} height={480} />
      </CacheProvider>
      <div className="statsRawData">
        <pre>{ JSON.stringify(Object.keys(dataView).reduce((acc, key) => ['data', 'netForecast'].includes(key) ? acc : { ...acc, ...{ [key]: dataView[key] } }, {}), false, 2) }</pre>
      </div>
    </>
  )
}

class Home extends React.Component {
  render () {
    return (
        <div id='content' className='home'>
          <StatsDashboard />
        </div>
    )
  }
}

export default Home
