import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAsync } from 'react-async'

import './BatchEditor.css'

const RiskData = ({ data, context }) => {
  const [applyButtonVisible, setApplyButtonVisible] = useState(false)
  const [botsData, setBotsData] = useState(data.botsData)
  const [botsDataPreview, setBotsDataPreview] = useState(data.botsData)
  context.setApplyButtonVisible = setApplyButtonVisible
  context.setBotsData = setBotsData
  context.getBotsData = () => botsData
  context.setBotsDataPreview = setBotsDataPreview
  context.getBotsDataPreview = () => botsDataPreview
  const { account, currency } = context
  const bots = botsDataPreview.map(botData => botData.bot)

  async function handleApplyRisk () {
    const mode = context.getRiskMode()
    let config
    if (mode === 'simple') {
      const bots = botsDataPreview.map(botData => botData.bot)
      config = bots
    } else if (mode === 'fixedVolume' || mode === 'fixedPercent') {
      config = {
        val: context.getVal()
      }
    }
    const updateBotsData = {
      mode,
      account,
      currency,
      config
    }
    await window.threeCommasManagerClient.updateBots(updateBotsData)
    setApplyButtonVisible(false)
  }

  function recapStringFromBotData (bot) {
    const quoteCurrency = bot.pairs[0].split('_')[0]
    return `TP: ${bot.take_profit}, BO: ${bot.base_order_volume} ${quoteCurrency}, SO: ${bot.safety_order_volume} ${quoteCurrency}, SOS: ${bot.safety_order_step_percentage}%, OS: ${bot.martingale_volume_coefficient}, SS: ${bot.allowed_deals_on_same_pair}`
  }

  return (
    <div className="riskDataContainer">
      <div className="botDataRecapList">
        {
          bots.map((bot) => (
            <div className="botDataRecap" key={bot.id}>
              <div className="botDataRecapName">{bot.name}</div>
              <div className="botDataRecapParams">{recapStringFromBotData(bot)}</div>
            </div>
          ))
        }
      </div>
      <div className="bottomRow">
        <button className={`button button_primary button_md applyRiskBtn ${applyButtonVisible ? '' : 'hidden'}`} onClick={handleApplyRisk}>Apply</button>
      </div>
    </div>
  )
}

RiskData.propTypes = {
  data: PropTypes.any,
  context: PropTypes.any
}

// You can use async/await or any function that returns a Promise
const loadRisk = async ({ param }, { signal }) => {
  return window.threeCommasManagerClient.getRisk()
}

const BatchEditorDashboard = () => {
  const { data, error, isPending } = useAsync({ promiseFn: loadRisk, param: 1 })
  if (isPending) return 'Loading...'
  if (error) return `Something went wrong: ${error.message}`
  if (data) {
    return (
      <>
        <div className="currencyRiskContainerList">
          {
            Object.keys(data.accounts).map((account) => {
              return Object.keys(data.accounts[account]).map((currency) => {
                const context = {
                  account: parseInt(account),
                  currency,
                  currencyData: data.accounts[account][currency]
                }
                return (
                  <div className="currencyRiskRowContainer" key={`${account}:${currency}:risk`}>
                    <div className="currencyRiskContainer">
                      <RiskData data={data.accounts[account][currency]} context={context} />
                    </div>
                  </div>
                )
              })
            })
          }
        </div>
      </>
    )
  }
  return null
}

class BatchEditor extends React.Component {
  render () {
    return (
        <div id='content' className='batchEditor'>
          <BatchEditorDashboard />
        </div>
    )
  }
}

export default BatchEditor
