/* eslint-disable no-unused-vars */

class RiskCalc {
  constructor ({ logger }) {
    this.logger = logger
  }

  getDealMaxVolume ({ maxSafetyOrders, martingaleStepCoefficient, martingaleVolumeCoefficient, safetyOrderStepPercentage, baseOrderVolume, safetyOrderVolume }) {
    const stepDeviation = (martingaleStepCoefficient === 1) ? maxSafetyOrders * safetyOrderStepPercentage : (Math.pow(martingaleStepCoefficient, maxSafetyOrders) - 1) / (martingaleStepCoefficient - 1) * safetyOrderStepPercentage
    const safetyOrders100DeviationR = (martingaleStepCoefficient === 1) ? 100 / safetyOrderStepPercentage : (Math.log((martingaleStepCoefficient - 1) * 100 / safetyOrderStepPercentage + 1) / Math.log(martingaleStepCoefficient))
    const safetyOrders100Deviation = Math.floor(safetyOrders100DeviationR)
    const actualMaxSafetyOrders = Math.min(safetyOrders100Deviation, maxSafetyOrders)
    const dealMaxVolume = (Math.pow(martingaleVolumeCoefficient, actualMaxSafetyOrders) - 1) / (martingaleVolumeCoefficient - 1) * safetyOrderVolume + baseOrderVolume
    return dealMaxVolume
  }

  getBaseOrderVolume (dealMaxVolume, { maxSafetyOrders, martingaleStepCoefficient, martingaleVolumeCoefficient, safetyOrderStepPercentage, safetyOrderVolume }) {
    const baseOrderVolume = dealMaxVolume * (martingaleVolumeCoefficient - 1) / (martingaleVolumeCoefficient + 2 * Math.pow(martingaleVolumeCoefficient, maxSafetyOrders) - 3)
    // const baseOrderVolume = dealMaxVolume * (martingaleVolumeCoefficient - 1) / (3 * (Math.pow(martingaleVolumeCoefficient, maxSafetyOrders) - 1))
    return baseOrderVolume
  }

  getConfigFromMaxVolume (account, bot, maxUsageAccountCurrencyTarget) {
    const maxUsageAccountCurrencyTargetDeal = maxUsageAccountCurrencyTarget / bot.max_active_deals

    const dealProps = {
      maxSafetyOrders: bot.max_safety_orders,
      martingaleStepCoefficient: parseFloat(bot.martingale_step_coefficient),
      martingaleVolumeCoefficient: parseFloat(bot.martingale_volume_coefficient),
      safetyOrderStepPercentage: parseFloat(bot.safety_order_step_percentage),
      baseOrderVolume: parseFloat(bot.base_order_volume),
      safetyOrderVolume: parseFloat(bot.safety_order_volume)
    }
    const leverageCoeff = bot.leverage_custom_value ? parseFloat(bot.leverage_custom_value) : 1
    const baseOrderVolume = this.getBaseOrderVolume(maxUsageAccountCurrencyTargetDeal, dealProps) * leverageCoeff
    return baseOrderVolume
  }

  getPositionVolume (deal) {
    switch (deal.strategy) {
      case 'long':
        return deal.bought_volume
      case 'short':
        return deal.sold_volume
    }
    return deal.bought_volume
  }

  getDealMaxVolumeFromDeal (deal, bot) {
    const leverageCoeff = bot.leverage_custom_value ? parseFloat(bot.leverage_custom_value) : 1
    const volume = parseFloat(this.getPositionVolume(deal)) / leverageCoeff
    if (deal.active_safety_orders_count >= deal.max_safety_orders) {
      return volume
    }

    const dealProps = {
      maxSafetyOrders: deal.max_safety_orders,
      martingaleStepCoefficient: parseFloat(deal.martingale_step_coefficient),
      martingaleVolumeCoefficient: parseFloat(deal.martingale_volume_coefficient),
      safetyOrderStepPercentage: parseFloat(deal.safety_order_step_percentage),
      baseOrderVolume: parseFloat(deal.base_order_volume),
      safetyOrderVolume: parseFloat(deal.safety_order_volume)
    }
    const maxVol = this.getDealMaxVolume(dealProps)

    const volumeCoveredWithCurrentSettingsProps = {
      maxSafetyOrders: deal.completed_safety_orders_count - 1,
      martingaleStepCoefficient: parseFloat(deal.martingale_step_coefficient),
      martingaleVolumeCoefficient: parseFloat(deal.martingale_volume_coefficient),
      safetyOrderStepPercentage: parseFloat(deal.safety_order_step_percentage),
      baseOrderVolume: parseFloat(deal.base_order_volume),
      safetyOrderVolume: parseFloat(deal.safety_order_volume)
    }
    const volumeCoveredWithCurrentSettings = this.getDealMaxVolume(volumeCoveredWithCurrentSettingsProps)

    const remainingFromConfigVol = (maxVol - volumeCoveredWithCurrentSettings) / leverageCoeff
    const dealMaxVol = remainingFromConfigVol + parseFloat(volume)
    return dealMaxVol
  }

  getSumAllBotDealsAtMaxSOFromDeals (bot, deals) {
    let sumAllBotDealsAtMaxSO = 0
    for (const deal of deals) {
      if (deal.bot_id !== bot.id) continue
      sumAllBotDealsAtMaxSO += this.getDealMaxVolumeFromDeal(deal, bot)
    }
    return sumAllBotDealsAtMaxSO
  }

  getMaxUsageAccountCurrencyFromActiveDeals (account, bots, deals) {
    let maxCurrencyUsageOnAccount = 0
    for (const bot of bots) {
      if (bot.account_id !== account) continue
      maxCurrencyUsageOnAccount += this.getSumAllBotDealsAtMaxSOFromDeals(bot, deals)
    }
    return maxCurrencyUsageOnAccount
  }

  getSumAllBotDealsAtMaxSOFromConfig (bot) {
    const dealProps = {
      maxSafetyOrders: bot.max_safety_orders,
      martingaleStepCoefficient: parseFloat(bot.martingale_step_coefficient),
      martingaleVolumeCoefficient: parseFloat(bot.martingale_volume_coefficient),
      safetyOrderStepPercentage: parseFloat(bot.safety_order_step_percentage),
      baseOrderVolume: parseFloat(bot.base_order_volume),
      safetyOrderVolume: parseFloat(bot.safety_order_volume)
    }
    const maxVolumePerDeal = this.getDealMaxVolume(dealProps)
    const leverageCoeff = bot.leverage_custom_value ? parseFloat(bot.leverage_custom_value) : 1
    const sumAllBotDealsAtMaxSO = (bot.max_active_deals * maxVolumePerDeal) / leverageCoeff

    return sumAllBotDealsAtMaxSO
  }

  getMaxUsageAccountCurrencyFromConfig (account, bots) {
    let maxCurrencyUsageOnAccount = 0
    for (const bot of bots) {
      if (bot.account_id !== account) continue
      maxCurrencyUsageOnAccount += this.getSumAllBotDealsAtMaxSOFromConfig(bot)
    }
    return maxCurrencyUsageOnAccount
  }

  getSumAllBotDealsAtMaxSOFromActiveAndMaxActiveDeals (bot, deals) {
    const sumAllBotDealsAtMaxSOFromActiveDeals = this.getSumAllBotDealsAtMaxSOFromDeals(bot, deals)
    const dealProps = {
      maxSafetyOrders: bot.max_safety_orders,
      martingaleStepCoefficient: parseFloat(bot.martingale_step_coefficient),
      martingaleVolumeCoefficient: parseFloat(bot.martingale_volume_coefficient),
      safetyOrderStepPercentage: parseFloat(bot.safety_order_step_percentage),
      baseOrderVolume: parseFloat(bot.base_order_volume),
      safetyOrderVolume: parseFloat(bot.safety_order_volume)
    }
    const leverageCoeff = bot.leverage_custom_value ? parseFloat(bot.leverage_custom_value) : 1
    const maxVolumePerDeal = this.getDealMaxVolume(dealProps) / leverageCoeff
    const sumAllBotDealsAtMaxSO = (bot.max_active_deals - bot.active_deals_count) * maxVolumePerDeal

    const sumAllBotDealsAtMaxSOFromActiveAndMaxActiveDeals = sumAllBotDealsAtMaxSOFromActiveDeals + sumAllBotDealsAtMaxSO
    return sumAllBotDealsAtMaxSOFromActiveAndMaxActiveDeals
  }

  getMaxUsageAccountCurrencyFromActiveAndMaxActiveDeals (account, bots, deals) {
    let maxCurrencyUsageOnAccount = 0
    for (const bot of bots) {
      if (bot.account_id !== account) continue
      maxCurrencyUsageOnAccount += this.getSumAllBotDealsAtMaxSOFromActiveAndMaxActiveDeals(bot, deals)
    }
    return maxCurrencyUsageOnAccount
  }

  getSumAllBotActiveDealsReserved (bot, deals) {
    const leverageCoeff = bot.leverage_custom_value ? parseFloat(bot.leverage_custom_value) : 1
    let sumAllBotDealsReserved = 0
    for (const deal of deals) {
      if (deal.bot_id !== bot.id) continue
      sumAllBotDealsReserved += parseFloat(deal.reserved_quote_funds) / leverageCoeff
    }
    return sumAllBotDealsReserved
  }

  getActiveDealsReserved (account, bots, deals) {
    let activeDealsReserved = 0
    for (const bot of bots) {
      if (bot.account_id !== account) continue
      activeDealsReserved += this.getSumAllBotActiveDealsReserved(bot, deals)
    }
    return activeDealsReserved
  }

  getSumAllBotActiveDealsBoughtVolume (bot, deals) {
    const leverageCoeff = bot.leverage_custom_value ? parseFloat(bot.leverage_custom_value) : 1
    let sumAllBotDealsBoughtVolume = 0
    for (const deal of deals) {
      if (deal.bot_id !== bot.id) continue
      sumAllBotDealsBoughtVolume += this.getPositionVolume(deal) / leverageCoeff
    }
    return sumAllBotDealsBoughtVolume
  }

  getActiveDealsBoughtVolume (account, bots, deals) {
    let activeDealsBoughtVolume = 0
    for (const bot of bots) {
      if (bot.account_id !== account) continue
      activeDealsBoughtVolume += this.getSumAllBotActiveDealsBoughtVolume(bot, deals)
    }
    return activeDealsBoughtVolume
  }

  getFixedPercentNewBotsConfig (account, currencyRisk, val) {
    const botsToUpdate = []
    const { botsData } = currencyRisk
    const bots = botsData.map(botData => botData.bot)
    const bankrollTotal = currencyRisk.position + currencyRisk.boughtVolume
    const maxUsageAccountCurrencyTarget = val * bankrollTotal / 100
    const maxUsageAccountCurrencyFromConfigBefore = this.getMaxUsageAccountCurrencyFromConfig(account, bots)
    for (const botData of botsData) {
      const { bot, firstPairCurrencyRate } = botData
      const botMaxRisk = this.getSumAllBotDealsAtMaxSOFromConfig(bot)
      const botRiskWeight = maxUsageAccountCurrencyFromConfigBefore / botMaxRisk
      const newBOFullPrecision = this.getConfigFromMaxVolume(account, bot, maxUsageAccountCurrencyTarget / botRiskWeight)
      const priceStepMultiplier = 1 / parseFloat(firstPairCurrencyRate.priceStep)
      const newBO = Math.round(newBOFullPrecision * priceStepMultiplier) / priceStepMultiplier
      if (newBO < parseFloat(firstPairCurrencyRate.minTotal)) {
        this.logger.info(`${bot.name} newBO lower than minimum price`)
        continue
      }
      if (Math.abs(newBO - parseFloat(bot.base_order_volume)) < parseFloat(firstPairCurrencyRate.priceStep)) {
        this.logger.info(`${bot.name} newBO too close to current price`)
        continue
      }
      const newSO = Math.floor(newBO * 2 * priceStepMultiplier) / priceStepMultiplier
      bot.base_order_volume = newBO.toString()
      bot.safety_order_volume = newSO.toString()
      botsToUpdate.push(bot)
    }
    return botsToUpdate
  }
}

module.exports = {
  RiskCalc
}
