import React from 'react'
import {
  // BrowserRouter as Router,
  Router,
  Switch,
  Route
} from 'react-router-dom'
import PropTypes from 'prop-types'

import { ProtectedRoute } from './Components/ProtectedRoute'
import { Login } from './Components/Login'
import { Home } from './Components/Home'
import { Risk } from './Components/Risk'
import { BatchEditor } from './Components/BatchEditor'
import { Pairs } from './Components/Pairs'
import { RecoveryGrid } from './Components/RecoveryGrid'
import { Signals } from './Components/Signals'
import { Config } from './Components/Config'
import './App.css'

function App ({ history }) {
  const sectionsMap = [
    ['/risk', Risk],
    ['/batchEditor', BatchEditor],
    ['/pairs', Pairs],
    ['/recoveryGrid', RecoveryGrid],
    ['/signals', Signals],
    ['/config', Config],
    ['/', Home]
  ]
  return (
    <Router history={history}>
      <Switch>
        <Route path='/login'>
          <Login history={history} />
        </Route>
        {
          sectionsMap.map(([path, component]) =>
            (
              <ProtectedRoute key={path} path={path} component={component} history={history} />
            )
          )
        }
      </Switch>
    </Router>
  )
}

App.propTypes = {
  history: PropTypes.any
}

export default App
