/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import './Login.css'

class Login extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      APIURL: window.threeCommasManagerClient.APIURL,
      username: '',
      password: ''
    }
    this.handleLoginBtn = this.handleLoginBtn.bind(this)
    this.doLogin = this.doLogin.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.APIURLRef = React.createRef()
    this.usernameRef = React.createRef()
    this.passwordRef = React.createRef()
    // this.history = useHistory()
  }

  static get propTypes () {
    return {
      history: PropTypes.any
    }
  }

  async doLogin ({ APIURL, username, password }) {
    const loginData = {
      APIURL,
      username,
      password
    }
    try {
      await window.threeCommasManagerClient.createSession(loginData)
    } catch (e) {
      console.error(e)
    }
    this.props.history.push('/')
  }

  handleLoginBtn () {
    const {
      APIURL,
      username,
      password
    } = this.state
    this.doLogin({ APIURL, username, password })
  }

  handleChange (e) {
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  };

  render () {
    return (
        <div id="content" className="login">
          <div id="loginBox">
            <input className="textInput textInput_md" type="text" name="APIURL" ref={this.APIURLRef} value={this.state.APIURL} onChange={this.handleChange}></input>
            <input className="textInput textInput_md" type="text" name="username" ref={this.usernameRef} value={this.state.username} onChange={this.handleChange}></input>
            <input className="textInput textInput_md" type="password" name="password" ref={this.passwordRef} value={this.state.password} onChange={this.handleChange}></input>
            <button className="button button_primary button_md button_block" onClick={this.handleLoginBtn}>Login</button>
          </div>
        </div>
    )
  }
}

export default Login
